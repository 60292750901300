import { itemInterface } from './items';
import axios from '@/helpers/forms/axios';

export interface moveInterface {
    id: number
    item: itemInterface
    itemId: number
    arrivable: object
    arrivableId: number
    arrivableType: string
    sourceable: object
    sourceableId: number
    sourceableType: string
    quantity: number
    notes: string
}

export const deleteMove = async (moveId: number): Promise<boolean> => {
    await axios.delete(`/api/moves/${moveId}`);
    return true;
};
