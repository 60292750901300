import { itemInterface } from '../repositories/items';
import { orderInterface } from '../repositories/orders';
import { unsellableItemInterface } from '../shipments/inbound';
import axios from '@/helpers/forms/axios';

export interface fixItemsResponse {
    unsyncedItems: itemInterface[]
    unsellableItems: unsellableItemInterface[]
}

const fetchFixItems = async (): Promise<fixItemsResponse> => {
    const response = await axios.get<fixItemsResponse>('/api/inventory/fixItems');
    return response.data;
};

const fetchOrderErrors = async (): Promise<orderInterface[]> => {
    const response = await axios.get<{ orders: orderInterface[] }>('/api/orders?filters[status]=error');

    return response.data.orders;
};

const resolveOrderErrors = async (orderId: number): Promise<void> => {
    return axios.get(`/api/orders/${orderId}/resolveOrderErrors`);
};

const convertUnsellableToDifferentCondition = async (unsellableItemId: number, itemId: number): Promise<void> => {
    return axios.post(`/api/shipments/inbound/inbound-shipment-unsellable-item/${unsellableItemId}/convert-to-different-item`, {
        itemId,
    });
};

export default {
    fetchFixItems,
    fetchOrderErrors,
    resolveOrderErrors,
    convertUnsellableToDifferentCondition,
};
