<template>
    <div class="w-full max-w-[10rem] lg:max-w-sm min-w-fit flex md:ml-0 pr-2 flex-shrink">
        <div
            v-if="userHasRoleOnCurrentServiceProvider"
            v-tooltip.bottom="'Lock the merchant selector so it doesn\'t change when you navigate around the portal'"
            class="self-center mr-2 cursor-pointer"
        >
            <LockOpenIcon
                v-if="!selectedMerchantLocked"
                class="w-6 h-6 text-gray-400"
                @click="toggleSelectedMerchantLocked"
            />

            <LockClosedIcon
                v-if="selectedMerchantLocked"
                class="w-6 h-6 text-red-500/50"
                @click="toggleSelectedMerchantLocked"
            />
        </div>

        <base-combobox
            v-if="!selectedMerchantLocked"
            id="clientSelect"
            class="lg:w-96"
            placeholder="Select a Merchant"
            :options="merchantComboboxOptions"
            :selected-value="currentMerchantOptional?.id ?? 0"
            title="Select Merchant"
            @option-selected="switchMerchant"
        />

        <h2
            v-if="selectedMerchantLocked"
            class="self-center mr-4 text-gray-300/75"
        >
            {{ selectedMerchantName }}
        </h2>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/vue/20/solid';
import useInertia from '@/composables/useInertia';
import { comboboxOptionInterface } from '@/components/elements/BaseCombobox.vue';
import { useNarrativeStore } from '@/stores/narrative';
import { useNarrative } from '@/composables/useNarrative';
import useAppPermissions from '@/composables/useAppPermissions';

const narrativeStore = useNarrativeStore();
const { currentServiceProvider, currentMerchantOptional, userHasRoleOnCurrentServiceProvider } = useNarrative();
const { hasRegisterMerchantsPermission } = useAppPermissions();

const selectedMerchantName = computed((): string => {
    if (currentMerchantOptional.value) {
        return currentMerchantOptional.value.name;
    }

    return 'All Merchants';
});
const merchantComboboxOptions = computed((): comboboxOptionInterface[] => {
    const options: comboboxOptionInterface[] = currentServiceProvider.value.merchants
        .filter(merchant => merchant.enabled)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((merchant) => {
            return {
                value: merchant.id,
                name: merchant.name,
                imageUrl: merchant.photoUrl,
                disabled: false,
            };
        });

    if (!options.length && hasRegisterMerchantsPermission) {
        options.push({
            value: 'new',
            name: 'Create a New Merchant',
            imageUrl: '',
            disabled: false,
        });
    }

    if (userHasRoleOnCurrentServiceProvider) {
        options.unshift({
            value: 0,
            name: currentMerchantOptional.value ? 'Clear Selected Merchant' : 'Select a Merchant',
            imageUrl: '',
            disabled: !currentMerchantOptional.value,
        });
    }

    return options;
});

const switchMerchant = (option: comboboxOptionInterface) => {
    if (option.value === 'new' && userHasRoleOnCurrentServiceProvider.value) {
        useInertia().visit('/staff/merchants/create');
        return;
    } else if (option.value === 'new') {
        useInertia().visit('/register-merchant');
        return;
    }

    const merchantId = typeof option.value === 'string' ? Number.parseFloat(option.value) : option.value;

    const merchant = currentServiceProvider.value.merchants.find(merchant => merchant.id === merchantId);

    if (!merchant) {
        narrativeStore.clearCurrentMerchant();

        return;
    }

    narrativeStore.setCurrentMerchant(merchant);
};

const selectedMerchantLocked = computed(() => narrativeStore.getSelectedMerchantLocked);
const toggleSelectedMerchantLocked = () => {
    narrativeStore.toggleSelectedMerchantLocked();
};
</script>
