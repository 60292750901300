import { ServiceProviderBillingStatus, billingAccountInterface, subscriptionInterface, subscriptionPlanPriceInterface } from '../serviceProviderBilling';
import { AppEntitlementEnum } from '@/compiler/types';
import { sendForm } from '@/helpers/forms/form';
import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';
import { invoiceInterface } from '@/compiler/billing';

export interface createPlanFormInterface {
    name: string
    trialDays: number
    active: boolean
    prices: {
        amount: number
        intervalType: 'month' | 'year'
        usagePrices: {
            type: 'outbound_shipment_items'
            tiers: {
                fromUnits: number
                toUnits: number | null
                amountPerUnit: number
            }[]
        }[]
    }[]
    entitlements: {
        [key in AppEntitlementEnum]: boolean
    }
}

const createPlan = async (form: createPlanFormInterface): Promise<any> => {
    return await sendForm({
        method: 'post',
        uri: '/api/admin/serviceProviderBilling/createPlan',
        form,
    });
};

export interface changeSubscriptionFormInterface {
    billingAccountId: string
    currentSubscriptionId: string
    newPlanPriceId: string
    shouldRefund: boolean
}

const changeSubscription = async (form: changeSubscriptionFormInterface): Promise<any> => {
    return await sendForm({
        method: 'post',
        uri: '/api/admin/serviceProviderBilling/changeSubscription',
        form,
    });
};

export interface extendTrialFormInterface {
    billingAccountId: string
    currentSubscriptionId: string
    trialEndsAt: string
}

const extendTrial = async (form: extendTrialFormInterface): Promise<any> => {
    return await sendForm({
        method: 'post',
        uri: '/api/admin/serviceProviderBilling/extendTrial',
        form,
    });
};

export interface billingAccountResponseInterface extends billingAccountInterface {
    name: string
    serviceProviderId: number
}

export interface billingAccountDetailsResponseInterface extends billingAccountInterface {
    name: string
    subscriptions: Array<subscriptionInterface & { planPrice: subscriptionPlanPriceInterface }>
    payments: {
        invoices: invoiceInterface[]
        openChargesTotal: number
        invoicedChargesTotal: number
        draftInvoicesTotal: number
        finalizedInvoicesTotal: number
        paidInvoicesTotal: number
        lastSevenMonthsPaidInvoicesTotal: {
            [key: string]: number
        }
    }
}

const fetchBillingAccounts = async (): Promise<billingAccountResponseInterface[]> => {
    const response = await axios.get<{ accounts: billingAccountResponseInterface[] }>('/api/admin/serviceProviderBilling/billingAccounts');

    return response.data.accounts;
};

const fetchBillingAccountDetails = async (accountId: string): Promise<billingAccountDetailsResponseInterface> => {
    const response = await axios.get<billingAccountDetailsResponseInterface>(`/api/admin/serviceProviderBilling/${accountId}`);

    return response.data;
};

const invoiceOpenCharges = async (billingAccountId: string): Promise<void> => {
    axios.post<{ accounts: billingAccountResponseInterface[] }>('/api/admin/serviceProviderBilling/invoiceOpenCharges', {
        billingAccountId,
    });
};

export interface ChargebeeServiceProviderAccount {
    id: number
    name: string
    createdAt: string
    updatedAt: string
    serviceProviderId: number
    chargebeeAccountId: string
    subscriptionIds: string[]
}

const fetchChargebeeAccounts = fetchPaginatedDataFunction<ChargebeeServiceProviderAccount>(
    '/api/admin/service-provider-billing/chargebee/accounts',
);

const createChargebeeAccountLink = async (serviceProviderId: number, chargebeeAccountId: string): Promise<void> => {
    return await axios.post(`/api/admin/service-provider-billing/chargebee/accounts`, {
        serviceProviderId,
        chargebeeAccountId,
    });
};

const getAccountStatus = async (serviceProviderId: number): Promise<ServiceProviderBillingStatus> => {
    const response = await axios.get<ServiceProviderBillingStatus>(`/api/admin/service-provider-billing/status?service_provider_id=${serviceProviderId}`);

    return response.data;
};

export default {
    fetchBillingAccounts,
    fetchBillingAccountDetails,
    createPlan,
    changeSubscription,
    extendTrial,
    invoiceOpenCharges,

    fetchChargebeeAccounts,
    createChargebeeAccountLink,

    getAccountStatus,
};
