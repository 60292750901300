<template>
    <Disclosure>
        <DisclosureButton class="w-full bg-white border border-gray-300" :class="`${open ? 'rounded-t-lg' : 'rounded-lg shadow-sm'} ${padding === 'wide' ? 'p-4' : 'p-2'}`" @click="open = !open">
            <div class="w-full flex justify-between items-center">
                <slot name="header" />
                <slot name="chevron" :open="open">
                    <ChevronDownIcon class="h-5 w-5" :class="open && 'rotate-180 transform'" />
                </slot>
            </div>
        </DisclosureButton>
        <DisclosurePanel v-show="open" static class="bg-white -mt-2 border border-t-0 border-gray-300 rounded-b-lg shadow-sm" :class="padding === 'wide' ? 'p-4' : 'p-2'">
            <slot />
        </DisclosurePanel>
    </Disclosure>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const props = defineProps({
    padding: {
        type: String,
        validator: (prop: string) => ['wide', 'narrow'].includes(prop),
        default: 'wide',
    },
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const open = ref(props.isOpen);
</script>
