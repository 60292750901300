import axios from '@/helpers/forms/axios';

export interface itemLabelInterface {
    id: number
    count: number
    expiryDate?: string
}

const requestFnskuLabels = async (items: Array<itemLabelInterface>): Promise<void> => {
    return await axios.post('/api/inventory/requestLabels', { items });
};

export default {
    requestFnskuLabels,
};
