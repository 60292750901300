import locations from './locations';
import axios from '@/helpers/forms/axios';
import { sendForm } from '@/helpers/forms/form';

export interface warehouseAddressInterface {
    id: number
    warehouseId: number
    addressLine1: string
    addressLine2: string | null
    addressLine3: string | null
    city: string
    stateProvince: string | null
    countryCode: string
    postalCode: string
    phoneNumber: string | null
    isResidential: boolean | null
}
export interface warehouseInterface {
    id: number
    name: string
    companyId: number
    defaultAddress: warehouseAddressInterface
    addresses?: warehouseAddressInterface[]
}

const fetchWarehouses = async (): Promise<warehouseInterface[]> => {
    const response = await axios.get<{ warehouses: warehouseInterface[] }>('/api/warehouses');

    return response.data.warehouses;
};

const fetchWarehouse = async (warehouseId: number): Promise<warehouseInterface> => {
    const response = await axios.get<{ warehouse: warehouseInterface }>(`/api/warehouses/${warehouseId}`);

    return response.data.warehouse;
};

export interface CreateOrUpdateWarehouseForm {
    name: string
    addressLine1: string
    addressLine2: string
    addressLine3: string
    city: string
    stateProvince: string
    postalCode: string
    countryCode: string
    isResidential: boolean
    phoneNumber: string
}

const updateWarehouse = async (warehouseId: number, form: CreateOrUpdateWarehouseForm): Promise<void> => {
    return sendForm({
        method: 'PUT',
        uri: `/api/warehouses/${warehouseId}`,
        form,
    });
};

const createWarehouse = async (form: CreateOrUpdateWarehouseForm): Promise<void> => {
    return sendForm({
        method: 'POST',
        uri: `/api/warehouses`,
        form,
    });
};

const deleteWarehouseAddress = async (warehouseId: number, warehouseAddressId: number) => {
    return await axios.delete(`/api/warehouses/${warehouseId}/addresses/${warehouseAddressId}`);
};

export default {
    fetchWarehouses,
    fetchWarehouse,
    updateWarehouse,
    createWarehouse,
    deleteWarehouseAddress,
    locations,
};
