import { computed } from 'vue';
import { useCurrentUserStore } from '@/stores/currentUser';

export default function useAppPermissions() {
    const currentUserStore = useCurrentUserStore();

    return {
        hasViewCompanyServicesPricePermission: computed(() => currentUserStore.can('viewCompanyServicesPrice')),
        hasViewListCompanyServicesPermission: computed(() => currentUserStore.can('companyServiceViewList')),
        hasEditCompanyServicePermission: computed(() => currentUserStore.can('editCompanyService')),
        hasCreateOutboundShipmentPermission: computed(() => currentUserStore.can('createOutboundShipments')),
        hasArrangeTransportPermission: computed(() => currentUserStore.can('arrangeTransport')),
        hasAddAttachmentsPermission: computed(() => currentUserStore.can('addAttachments')),
        hasRegisterMerchantsPermission: computed(() => currentUserStore.can('registerMerchants')),
        hasUpdateShippedInboundShipmentItemsPermission: computed(() => currentUserStore.can('updateShippedInboundShipmentItems')),
        hasUpdateReceivedInboundShipmentItemsPermission: computed(() => currentUserStore.can('updateReceivedInboundShipmentItems')),
        hasConvertItemToBundlePermission: computed(() => currentUserStore.can('convertItemToBundle')),
    };
};
