import axios from '@/helpers/forms/axios';

const convertToBundle = async (itemId: number): Promise<void> => {
    return await axios.get(`/api/inventory/${itemId}/convert-to-bundle`);
};

const deleteFromBundle = async (bundleItemId: number, deletingItemId: number): Promise<void> => {
    return await axios.post(`/api/inventory/${bundleItemId}/bundles/delete-item`, {
        itemId: deletingItemId,
    });
};

const addItemsToBundle = async (bundleItemId: number, itemIds: number[]): Promise<void> => {
    return await axios.post(`/api/inventory/${bundleItemId}/bundles/add-items`, { itemIds });
};

export default {
    convertToBundle,
    deleteFromBundle,
    addItemsToBundle,
};
