import { Currency } from 'dinero.js';
import { merchantInterface } from './merchants';
import axios from '@/helpers/forms/axios';

export enum ServiceProviderUserRole {
    Owner = 'owner',
    OfficeEmployee = 'office',
    WarehouseEmployee = 'warehouse',
}

export enum Feature {
    orders = 'orders',
    amazonShipments = 'amazon_shipments',
    locations = 'locations',
}

export const serviceProviderPermissions = [
    'companyServiceViewList',
    'createOutboundShipments',
    'arrangeTransport',
    'addAttachments',
    'requireCreditCard',
    'registerMerchants',
    'updateShippedInboundShipmentItems',
    'updateReceivedInboundShipmentItems',
    'convertItemToBundle',
] as const;
export type ServiceProviderPermissionEnum = typeof serviceProviderPermissions[number];

export interface serviceProviderInterface {
    id: number
    name: string
    subdomainName: string
    domainName: string
    platformName: string
    platformKey: number
    platformDomain: string
    logoFile: string
    daysOfFreeStorage: number
    defaultAutomaticClientBilling: boolean
    supportEmail: string
    faviconFile: string
    stripeAccountId: string
    logoBackground: string
    currency: Currency
    availablePermissions: ServiceProviderPermissionEnum[]
    shippingSettings: {
        pick: {
            locationScan: 'required' | 'optional' | 'disabled'
            itemScan: 'required' | 'required_first_pick' | 'optional' | 'disabled'
        }
    }
    viewSettings: {
        metric?: {
            enabled: boolean
        }
        shipments?: {
            addItems?: {
                showExpiryDate?: {
                    enabled: boolean
                    minimumDays?: number
                }
            }
            createInboundShipmentDefault?: {
                enabled: boolean
            }
        }
        items?: {
            showCondition: {
                enabled: boolean
            }
        }
        sounds?: {
            enabled: boolean
        }
    }
    merchants: merchantInterface[]
}

async function fetchCurrentServiceProvider(): Promise<serviceProviderInterface> {
    const response = await axios.get<{ data: serviceProviderInterface }>('/api/narrative/serviceProvider');

    return response.data.data;
};

export default {
    fetchCurrentServiceProvider,
};
