import axios from '@/helpers/forms/axios';
import { itemInterface } from '@/api/repositories/items';

export interface createInventoryItemResponse {
    message: string
    itemDetails: itemInterface
}

export interface createIdentifierInterface {
    identifierType: string
    identifier: string
}

const createIdentifier = async (item: itemInterface, payload: createIdentifierInterface): Promise<createInventoryItemResponse> => {
    return await axios.post(`/api/inventory/${item.id}/identifier`, payload);
};

const deleteIdentifier = async (identifierId: number): Promise<void> => {
    return await axios.delete(`/api/identifier/${identifierId}`);
};

export default {
    createIdentifier,
    deleteIdentifier,
};
