import exports from './exports';
import axios from '@/helpers/forms/axios';

export interface MetabaseReportParameter {
    name: string
    value: string | number | null | false
}

export interface MetabaseReport {
    category: string
    name: string
    description: string
    id: number
    merchantFilterable: boolean
};

const getServiceProviderReportEmbedUrl = async (reportId: number, params: MetabaseReportParameter[]): Promise<string> => {
    const reportParams: { [key: string]: string | number | null | false } = {};
    if (params.length) {
        params.forEach((param) => {
            reportParams[param.name] = param.value;
        });
    }

    const response = await axios.post<{ url: string }>('/api/reports/service-providers/embed', {
        reportId,
        reportParams,
    });

    return response.data.url;
};

export default {
    getServiceProviderReportEmbedUrl,
    exports,
};
